@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Comforter+Brush&family=Fuzzy+Bubbles:wght@400;700&family=Source+Sans+Pro:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Comforter+Brush&family=Fuzzy+Bubbles:wght@400;700&family=Roboto+Condensed:wght@400;700&family=Source+Sans+Pro:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,400;0,700;1,300&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
select,
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"] {
  font-size: 16px;
}
body,
html {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
html,
body {
  width: auto !important;
  overflow-x: hidden !important;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select:focus,
  textarea:focus,
  input:focus {
    font-size: 16px;
  }
}

img {
  background-position: cover;
}
body {
  font-family: "Source Sans Pro", sans-serif;
  height: 100%;
  width: 100%;
}
textarea:focus,
input:focus {
  outline: none;
}

.ring {
  color: #0b8b3a;
  color: var(--logoColor);
  border-bottom: 1px solid #013a17;
  border-bottom: 1px solid var(--heroTextColor);
}
.ring:hover {
  color: #013a17;
  color: var(--heroTextColor);
}
.call-main {
  height: 1200px;
}
:root {
  --logoColor: #0b8b3a;
  --logoColorLight: #22c55e;
  --logoColorLightLight: #8feeb2;
  --navback: #dfe6d8;
  --lightGray: #334155;
  --ultraLightGray: #f0fdf4;
  --letterSpace: 1px;
  --filterText: #374151;
  --heroTextColor: #013a17;
}
ul {
  list-style-type: none;
}
a {
  text-decoration: none;
  color: white;
}
button {
  cursor: pointer;
}

.nav-container {
  font-family: "Source Sans Pro", sans-serif;
  background: #dfe6d8;
  background: var(--navback);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 3.8rem;
}
.nav-container div {
  display: flex;
}
.nav-btn {
  cursor: pointer;
  font-weight: bold;
  font-size: 1.5rem;
  padding: 0.5rem;
  color: #334155;
  color: var(--lightGray);
}
.nav-btn-container {
  display: flex;
  align-items: center;
}
.logo {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.5rem;
}
.logo h4 {
  color: #334155;
  color: var(--lightGray);
  font-size: 1.2rem;
  margin-left: 0.4rem;
  letter-spacing: 1px;
  letter-spacing: var(--letterSpace);
}
.logo p {
  color: #22c55e;
  color: var(--logoColorLight);
  font-weight: 700;
  font-size: 1.3rem;
}
.logo-text {
  display: flex;
  align-items: center;
}
.logo-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  border: 3.5px solid #22c55e;
  border: 3.5px solid var(--logoColorLight);
}
.logo-icon {
  color: #22c55e;
  color: var(--logoColorLight);
  font-weight: bold;
}
.login-icon {
  font-size: 1.4rem;
  color: #334155;
  color: var(--lightGray);
}
.login-text {
  margin: 0 0.4rem;
  font-size: 1rem;
  letter-spacing: 1px;
  letter-spacing: var(--letterSpace);
}
.nav-login-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
}
.nav-login-container:hover {
  color: #0b8b3a;
  color: var(--logoColor);
}
.filter-container {
  background: #f2f5f8;
  height: 4rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.filter-ett {
  margin: 0.5rem 0.5rem;
}
.filter-ett h4 {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 700;
  font-size: 1rem;
  color: #374151;
  color: var(--filterText);
}
.filter-ett p {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 0.9rem;
}
.filter-container button {
  margin: 0.5rem 0.5rem;
  width: 6.6rem;
  height: 2.6rem;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: bold;
  color: white;
  background: #0b8b3a;
  background: var(--logoColor);
  border: none;
  border-radius: 4px 4px;
  letter-spacing: 0.5px;
  font-size: 1rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.filter-container button:hover {
  border: 2px solid #22c55e;
  border: 2px solid var(--logoColorLight);
}
.arrow-down {
  font-size: 0.7rem;
  line-height: 50px;
  margin-left: 0.3rem;
}
.nav-menu {
  position: fixed;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  display: flex;
  justify-content: center;
  background: white;
  width: 100%;
  height: 100vh;
  z-index: 99;
  transition: all 0.3s linear;
  z-index: 1000000;
}
.show-menu {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}
.divider {
  width: 5rem;
  margin: 0.3rem 0;
  height: 1px;
  background: #22c55e;
  background: var(--logoColorLight);
}
.nav-links {
  color: #6b7280;
  font-size: 1.4rem;
  border-bottom: 1px solid #0b8b3a;
  border-bottom: 1px solid var(--logoColor);
}
.nav-list {
  margin: 1rem 1rem;
  font-family: "Source Sans Pro", sans-serif;

  letter-spacing: 1px;

  letter-spacing: var(--letterSpace);
}
.navbar-con {
  display: flex;
  color: #374151;
  color: var(--filterText);
  opacity: 0.7;
}

.nn {
  display: flex;
  margin-top: 1rem;
  margin-right: 0.5rem;

  font-weight: 700;
}
.navbar-con {
  cursor: pointer;
  display: flex;
  flex-direction: column;
}
.navbar-con p {
  margin-top: 0.3rem;
  font-size: 0.8rem;
  color: #0b8b3a;
  color: var(--logoColor);
}
.navbar-con a {
  margin: 0.3rem 0;
  cursor: pointer;
  color: black;
  font-weight: bold;
  opacity: 0.8;
}
.navbar-con a:hover {
  color: #0b8b3a;
  color: var(--logoColor);
}
.navbar-con h4:hover {
  color: #0b8b3a;
  color: var(--logoColor);
}
.search {
  margin-right: 0.5rem;
}
.delare {
  margin: 1rem;
  width: 12rem;
  height: 1px;
  background: #374151;
  background: var(--filterText);
  opacity: 0.2;
}
.close-nav-btn {
  font-size: 1.1rem;
  opacity: 0.7;
  cursor: pointer;
}
.filter-menu-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.filter-menu-buttons button {
  margin: 1rem 0.1rem;
  width: 40%;
  height: 1.8rem;
  border: none;
  background: #22c55e;
  background: var(--logoColorLight);
  color: white;
  letter-spacing: 1px;
  letter-spacing: var(--letterSpace);
  font-weight: 700;
  opacity: 0.7;
}
.filter-menu {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  position: fixed;
  -webkit-transform: translateY(-200%);
          transform: translateY(-200%);
  width: 100%;
  height: auto;
  z-index: 99;
  transition: all 0.3s linear;
}
.show {
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.filter-menu-nav {
  height: 3rem;
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.filter-menu-nav h4 {
  opacity: 0.8rem;
  font-size: 1rem;
  margin: 0 0.5rem;
}
.cancel-text {
  color: #0b8b3a;
  color: var(--logoColor);
  cursor: pointer;
}
.onpage-buttons {
  margin-top: 1rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}
.not-active {
  border-bottom: 1px solid gray;
  width: 33.33%;
  font-size: 0.85rem;
  cursor: pointer;
  color: rgb(75, 73, 73);
}

.active {
  border-bottom: 3px solid #0b8b3a;
  border-bottom: 3px solid var(--logoColor);
  width: 33.33%;
  color: #0b8b3a;
  color: var(--logoColor);
  font-size: 0.85rem;
  cursor: pointer;
}
.onpage-buttons div {
  display: flex;
  justify-content: center;
  font-size: 0.9rem;
  font-weight: bold;
}

.onpage-buttons span {
  margin-left: 0.2rem;
  margin-bottom: 0.2rem;
  letter-spacing: 0.5px;
}
.active h4,
.not-active h4 {
  margin-bottom: 0.3rem;
  opacity: 0.7;
}
.alt {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  background: white;
}
.alt select,
button {
  margin: 1rem 0.5rem;
  border: none;
  border-radius: 4px 4px;
  background: transparent;
}
.alt select {
  background: white;
  width: 70%;
  height: 2.2rem;
  cursor: pointer;
  color: #334155;
  color: var(--lightGray);
  border: 1px solid rgb(214, 209, 209);
}
.alt button {
  height: 2.8rem;
  width: 6rem;
  color: #334155;
  color: var(--lightGray);
  border: 1px solid rgb(214, 209, 209);
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  font-weight: 700;
  font-size: 1rem;
  background: #dfe6d8;
  background: var(--navback);
}
.antal-objects {
  margin: 0.5rem 1rem;
  color: #334155;
  color: var(--lightGray);
  font-size: 1rem;
  font-weight: 100;
  border-bottom: 1px solid gray;
  width: 5.7rem;
}
.alt button:hover {
  background: white;
}

.addBanner-info {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 38%;
  background: white;
  margin-left: 0.3rem;
  width: 11rem;
  height: 2.8rem;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.addBanner-info h4 {
  font-size: 0.9rem;
}
.addBanner-info p {
  font-size: 0.9rem;
}
.bildskallvahar {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.4rem;
  width: 2.4rem;
  border-radius: 50%;
  border: 2px solid green;
  margin: 0 0.2rem;
}
.adBanner-image-container {
  position: relative;
  border-radius: 6px 6px;
  margin: 1.3rem auto;
  width: 95%;
  position: relative;
  height: 100%;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.adBanner-image-container img {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  height: 200px;
  width: 100%;
}
.construct {
  font-size: 1.4rem;
  color: #0b8b3a;
  color: var(--logoColor);
}
.map {
  margin-right: 0.3rem;
  font-size: 1.1rem;
}
.adbanner-tjanster {
  margin-top: 1rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  color: #0b8b3a;
  color: var(--logoColor);
  cursor: pointer;
  font-size: 0.9rem;
}

.adBanner-footer h4 {
  font-size: 0.9rem;
  font-weight: 100;
}
.adbanner-logo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.adbanerlogga {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #dfe6d8;
  background: var(--navback);
  margin-right: 0.5rem;
  margin-top: 0.2rem;
  width: 5rem;
  height: 2.2rem;
  font-family: "Fuzzy Bubbles", cursive;
}
.objects img {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  height: 200px;
  width: 100%;
  object-fit: cover;
  background-position: center;
}
.objects-container {
  display: grid;
  grid-template-columns: 1fr;
  border-radius: 6px 6px;
  margin: 1rem auto;
  width: 99%;
  height: 100%;
  /* box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05); */
}
.objects {
  cursor: pointer;
  font-family: "Roboto Condensed", sans-serif;
  position: relative;
  margin-bottom: 1.2rem;
  border-radius: 6px 6px;
  width: 100%;
  height: 420px;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  background: white;
}

.hjarta {
  color: rgb(158, 155, 155);
  margin-right: 0.3rem;
}
.fill-hjarta {
  color: #0b8b3a;
  color: var(--logoColor);
  margin-right: 0.3rem;
}
.objects-footer {
  display: flex;
}
.objects-footer span {
  margin-top: 1rem;
  margin-right: 3rem;
  font-weight: 700;
}
.objects-footer-first {
  margin-left: 0.5rem;
}
.objects-logo {
  display: flex;
  justify-content: space-between;
}
.objectsreal-logo {
  font-size: 0.8rem;
  margin-right: 0.2rem;
}
.objectsreal-logo h4 {
  font-size: 0.8rem;
}
.ccc {
  font-size: 0.7rem;
}
.circlar {
  width: 1.5rem;
  height: 1.5rem;
}
.objects-btn-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  padding: 0.5rem;
}
.objects-btn-container button {
  background: #c3f1d4;
  height: 2rem;
  width: 6rem;
  margin: 0.8rem 0.2rem;
  color: #0b8b3a;
  color: var(--logoColor);
}
.objects-btn-container button:hover {
  background: transparent;
  border: 1px solid #c3f1d4;
}
.object-location-text {
  font-size: 1rem;
  color: #374151;
  color: var(--filterText);
  opacity: 0.9;
  letter-spacing: 1px;
  letter-spacing: var(--letterSpace);
}
.object-plot-icon {
  font-size: 0.9rem;
  color: #22c55e;
  color: var(--logoColorLight);
  font-weight: 700;
  margin-right: 0.4rem;
}
.objectsreal-logo,
.object-location-text {
  margin: 0.3rem 0.2rem;
}
.footer {
  background: #013a17;
  height: 560px;
  display: column;
  color: white;
}
.footer div {
  width: 94%;
  margin: 0 auto;
}
.footer-ul {
  display: flex;
  flex-direction: column;
}
.form-container {
  width: 100%;
  height: 100%;
  margin-top: 1rem;
}
.form-container h2 {
  color: white;
}

.form input {
  width: 16rem;
  height: 2rem;
  border: none;
  color: #0b8b3a;
  color: var(--logoColor);
  border-radius: 4px 4px;
  margin-top: 0.4rem;
  font-size: 0.9rem;
  letter-spacing: 1px;
  letter-spacing: var(--letterSpace);
}

.footer-mail {
  color: white;
  border: 2px solid white;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  height: 2.4rem;
  width: 2.4rem;
  border-radius: 50%;
}
.sub-btn {
  background: #22c55e;
  background: var(--logoColorLight);
  padding: 0.5rem;
  color: white;
  letter-spacing: 1px;
  letter-spacing: var(--letterSpace);
}
.sub-btn:hover {
  background: white;
  color: #22c55e;
  color: var(--logoColorLight);
  border: 1px solid #22c55e;
  border: 1px solid var(--logoColorLight);
}
.foreginer {
  color: #0b8b3a;
  color: var(--logoColor);
}

.singel-object-heartmap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.singel-object-heartmap button {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  padding: 0.4rem;
  border: 1px solid rgb(163, 161, 161);
  font-size: 0.9rem;
  font-weight: bold;
  opacity: 0.8;
}
.singel-object-heartmap div {
  display: flex;
}
.singel-object-container {
  position: relative;
  margin-top: 1rem;
  height: auto;
}
.objects-link {
  color: #0b8b3a;
  color: var(--logoColor);
}
.singel-object img {
  width: 100vw;
  height: auto;
}

.single-object-img-container {
  position: relative;
}
.singel-img-left-arr {
  opacity: 0.7;
  display: flex;
  position: absolute;
  font-size: 1.5rem;
  top: 40%;
  left: 0%;
  color: white;
  padding: 0.3rem;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  background: #334155;
  background: var(--lightGray);
  margin: auto 0.2rem;
}
.singel-img-right-arr {
  margin: auto 0.2rem;
  opacity: 0.7;
  display: flex;
  padding: 0.3rem;
  background: #334155;
  background: var(--lightGray);
  position: absolute;
  font-size: 1.5rem;
  top: 40%;
  right: 0%;
  color: white;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}
.singel-object-info {
  font-family: "Roboto Condensed", sans-serif;
  margin-left: 0.3rem;
}
.singel-object-info h2 {
  color: #374151;
  color: var(--filterText);
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1.1rem;
  letter-spacing: 1px;
  letter-spacing: var(--letterSpace);
  margin-left: 0.3rem;
  margin-top: 0.5rem;
}
.singel-object-info p {
  margin-left: 0.3rem;
  margin-top: 0.1rem;
  letter-spacing: 1px;
  letter-spacing: var(--letterSpace);
  font-size: 0.83rem;
}
.map-btn-singel-object {
  color: rgb(90, 87, 87);
  border-bottom: 1px solid #0b8b3a;
  border-bottom: 1px solid var(--logoColor);
  font-size: 0.8rem;
}
.singel-object-info h4 {
  margin-left: 0.3rem;
  letter-spacing: 2px;
  color: #0b8b3a;
  color: var(--logoColor);
  font-weight: 100;
}
.divider-singel-object {
  margin: 0.5rem 0.7rem;
  background: gray;
  border: 0.5px solid rgb(167, 164, 164);
  opacity: 0.5;
}
.singel-object-banner {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  color: #0b8b3a;
  color: var(--logoColor);
  z-index: 99;
  background: white;
  opacity: 0.6;
  height: 2.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.col-1 {
  display: flex;
  margin-left: 0.3rem;
}
.ul-title li {
  margin: 0.4rem 0.3rem;
}
.ul-besk li {
  margin: 0.4rem 4rem;
  font-weight: bold;
}
.bid-comp {
  border-radius: 4px 4px;
  margin: 1rem auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 96%;
  height: 3.4rem;
  background: #dfe6d8;
  background: var(--navback);
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.bid-comp div {
  display: flex;
  align-items: center;
}
.bid-link {
  color: black;
  font-size: 0.9rem;
  letter-spacing: 0.5px;
}
.bid-btn {
  border-bottom: 1px solid black;
  margin-right: 0.5rem;
}
.hammer {
  margin-left: 1rem;
  margin-right: 1rem;
  font-size: 2rem;
}
.bid-comp p {
  font-size: 1.05rem;
  font-weight: bold;
}
.singel-object-fact {
  margin: 1rem 0;
}
.singel-object-infotext {
  position: relative;
  margin: 1rem auto;
  line-height: 25px;
  font-size: 1.1rem;
  font-weight: 100;
  max-width: 90%;
  overflow: hidden;
  height: 100%;
  transition: all 0.3s linear;
}
.singel-object-infotext span {
  font-size: 0.9rem;
  font-weight: bold;
  opacity: 0.7;
}
.singel-object-infotext-btns {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 99;
  background: white;
  width: 100%;
  transition: all 0.3s linear;
}
.singel-object-infotext-btns button {
  background-color: #0b8b3a;
  z-index: 1000;
  color: white;
  height: 3rem;
  border-radius: 4px 4px;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  margin: 0.3rem 0;
  font-size: 0.9rem;
  font-weight: bold;
  opacity: 0.7;
}
.soitbt-1 {
  background: white;
  border: 0.5px solid gray;
  color: black;
}
.soitbt-2 {
  background: #0b8b3a;
  background: var(--logoColor);
  color: white;
}
.hidden {
  display: none;
}
.interest-root {
  position: absolute;
  background: #dfe6d8;
  background: var(--navback);
  height: 300px;
  width: 96%;
  border-radius: 5px 5px;
  margin: 2rem 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 100;
  border: 1px solid rgb(223, 219, 219);
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}
.interest-root div {
  margin: 0.5rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.interest-root input {
  width: 10rem;
}
.interest-root textarea {
  width: 20rem;
  border-radius: 5px 5px;
  border: 1px solid rgb(209, 206, 206);
}
@media screen and (min-width: 500px) {
  .singel-object-container {
    border: 1px solid rgb(221, 215, 215);

    border-top-left-radius: 4px;
    border-top-right-radius: 3px;
  }

  .jjj {
    display: flex;
    position: relative;
    height: 100%;
  }

  .bid-div {
    max-width: 60%;
  }
  .singel-banner {
    max-width: 700px;
  }
  .ttt {
    max-width: 700px;
  }
  .singel-object-infotext {
    max-width: 300px;
  }
  .objects-container {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
    gap: 1rem;
    grid-template-rows: 1fr;
  }
  .onpage-buttons span {
    font-size: 1rem;
  }
  .map-holder {
    display: flex;
  }
  .adBanner-image-container {
    height: 260px;
    margin-left: 1rem;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    margin-right: 0.5rem;
  }
}
@media screen and (max-width: 500px) {
  .user-profile-info {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (min-width: 705px) {
  .adBanner-image-container {
    width: 350px;
  }
  .testmiCont{
    justify-content: center;
    max-width: 1000px;
    margin: 0 auto;
  }
  .yepyeptitle{
    text-align: center;
  }
}
@media screen and (min-width: 900px) {
  .adBanner-image-container {
    width: 450px;
    margin-right: 0.5rem;
  }
  .new-singel-obj-width {
    display: flex;
  }
  .related-item-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .singel-object-container {
    margin-left: 1rem;
  }
}
@media screen and (min-width: 1100px) {
  .objects-container {
    grid-template-columns: repeat(3, 1fr);
  }
  @media screen and (min-width: 700px) {
    .adBanner-image-container {
      width: 550px;
      margin-right: 0.5rem;
    }
  }
}

.brokers-comp-div {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.brokers-comp-div h2 {
  margin-left: 1rem;
  font-size: 1.2rem;
  color: #334155;
  color: var(--lightGray);
  margin-bottom: 0.5rem;
}
.brokers-comp-div p {
  margin-left: 1rem;
  margin-bottom: 0.4rem;
  border-bottom: 1px solid gray;
  width: 110px;
  font-weight: bold;
  opacity: 0.6;
}
.brokers-comp-div h6 {
  margin-left: 1rem;
  font-size: 0.9rem;
  font-weight: 100;
  margin-bottom: 1rem;
  letter-spacing: 1px;
  color: #0b8b3a;
  color: var(--logoColor);
}

.bbb button {
  width: 280px;
  height: 2.4rem;
  background: white;
  border: 1px solid rgb(192, 189, 189);
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  font-size: 0.9rem;
  font-weight: bold;
  opacity: 0.8;
}
.brokers-logo-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bbb {
  margin-left: 0.5rem;
}
.brokers-logo {
  width: 6rem;
  margin-right: 1rem;
}
.icon-broker {
  margin-right: 0.2rem;
}
.sameoffice {
  margin-left: 1rem;
  font-size: 0.9rem;
  font-weight: 100;
  border-bottom: 1px solid gray;
  width: 200px;
  margin-bottom: 1rem;

  cursor: pointer;
}
.brokers-divider {
  height: 3rem;
}
.map-container {
  height: 410px;
  width: 100%;
  margin: 1rem auto;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}
.user-icon-container {
  border: 3px solid #22c55e;
  border-radius: 50%;
  padding: 0.1rem;
  margin-right: 0.5rem;
}
.user-icon {
  border-radius: 50%;
  width: 1.8rem;
  height: 1.8rem;
}
.user-icon-container:hover {
  background: #0b8b3a;
}
.user-modal {
  position: fixed;
  right: 1%;
  display: flex;
  flex-direction: column;
  width: 20rem;
  height: 18rem;
  background: white;
  border-radius: 8px 8px;
  z-index: 1000;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  transition: all 0.3s linear;
  -webkit-transform: translateX(110%);
          transform: translateX(110%);
}
.user-account {
  font-size: 0.9rem;
}
.show-user-modal {
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
}
.user-modal div {
  margin: 0.5rem 0;
  display: flex;
  align-items: center;
}
.user-modal img {
  display: flex;
  align-items: center;
  height: 2.4rem;
  width: 2.4rem;
  border-radius: 50%;
}
.user-modal p {
  margin-left: 1rem;
}
.user-modal-link {
  color: #374151;
  color: var(--filterText);
  margin: 1rem auto;
  opacity: 0.7;
}
.fiUser {
  opacity: 0.7;
}
.fiUser:hover {
  color: #0b8b3a;
  color: var(--logoColor);
}
.user-modal-link:hover {
  color: #0b8b3a;
  color: var(--logoColor);
}
.user-logout {
  cursor: pointer;
  width: 94%;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  height: 2.4rem;
  border: 1px solid rgb(201, 197, 197);
  margin: 1srem auto;
}
.user-logout:hover {
  color: #0b8b3a;
  color: var(--logoColor);
}
.footer-bottom-info {
  height: 5.4rem;
  background: #056d2b;
}
.footer-links {
  color: white;
}
.footer li {
  margin: 0.5rem 0;
  font-size: 1rem;
  font-family: "Source Sans Pro", sans-serif;
  width: 200px;
  display: flex;

  align-items: center;
}
.footer li:hover {
  border-bottom: 1px solid white;
}
.insta-fot {
  margin-right: 0.4rem;
}
.footer-bottom-info div {
  display: flex;
  justify-content: center;
}
.footer-bottom-info button:hover {
  border-bottom: 1px solid white;
}
.footer-bottom-info span {
  color: white;
  font-size: 0.85rem;
}
.sums {
  display: flex;
  justify-content: space-evenly;
  border-bottom: 1px solid gray;
}
.sums div {
  display: flex;
  flex-direction: column;
}
.sums span {
  font-weight: bold;
  margin-bottom: 0.1rem;
}
.pop {
  display: flex;
  justify-content: space-evenly;
}
.pop-btn {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 1rem;
  padding: 0.2rem;
}
.pop-active {
  border-bottom: 2px solid green;
}
.not-loggedin-modal {
  position: absolute;
  z-index: 3000;
  background: #dfe6d8;
  background: var(--navback);
  width: 14rem;
  height: 10rem;
  border-radius: 5px 5px;
  top: 25%;
  left: 2%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  color: #0b8b3a;
  color: var(--logoColor);
}
.not-loggedin-modal button {
  width: 96%;
  border: 1px solid gray;
  text-align: center;
  height: 2rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  padding: 0.5rem;
  letter-spacing: 1px;
  background: #0b8b3a;
  background: var(--logoColor);
  color: white;
}
.not-loggedin-modal button:hover {
  background: #dfe6d8;
  background: var(--navback);
  color: #0b8b3a;
  color: var(--logoColor);
}
.ad-page {
  border-top: 1px solid #0b8b3a;
  border-top: 1px solid var(--logoColor);
  height: 600px;
  width: 100vw;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #dfe6d8;
  background: var(--navback);
}
.ad-page h1 {
  font-size: 2.6rem;
  width: 100%;
  color: #064e3b;
  margin-bottom: 1.2rem;
  letter-spacing: 1px;
}
.ads-prices-container {
  background: #dfe6d8;
  background: var(--navback);
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 900px;
}
.ads-prices-container h2 {
  width: 7.6rem;
  color: #013a17;
  text-align: center;
  margin-left: 3rem;
  border-radius: 5px 5px;
  margin-bottom: 2rem;
}
.ad-page h4 {
  margin: 0 auto;
  line-height: 30px;
  max-width: 90%;
  color: #064e3b;
  margin-bottom: 1.2rem;
  letter-spacing: 1px;
  font-size: 1.1rem;
  font-weight: 100;
}
.underline-text {
  font-weight: bold;
}
.nav-ad-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
  color: black;
}
.adsplus {
  margin-right: 0.2rem;
}
.box {
  align-self: flex-end;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  color: #013a17;
  font-size: 3rem;
  height: 100px;
  margin: 0 auto 0 auto;
  -webkit-transform-origin: bottom;
          transform-origin: bottom;
  width: 200px;
}
.bounce-5 {
  -webkit-animation-name: bounce-5;
          animation-name: bounce-5;
  -webkit-animation-timing-function: ease;
          animation-timing-function: ease;
}
@-webkit-keyframes bounce-5 {
  0% {
    -webkit-transform: scale(1, 1) translateY(0);
            transform: scale(1, 1) translateY(0);
  }

  50% {
    -webkit-transform: scale(1, 0.9) translateY(0);
            transform: scale(1, 0.9) translateY(0);
  }

  100% {
    -webkit-transform: scale(1, 1) translateY(0);
            transform: scale(1, 1) translateY(0);
  }
}
@keyframes bounce-5 {
  0% {
    -webkit-transform: scale(1, 1) translateY(0);
            transform: scale(1, 1) translateY(0);
  }

  50% {
    -webkit-transform: scale(1, 0.9) translateY(0);
            transform: scale(1, 0.9) translateY(0);
  }

  100% {
    -webkit-transform: scale(1, 1) translateY(0);
            transform: scale(1, 1) translateY(0);
  }
}

.ads-package {
  position: relative;
  margin: 3rem auto;
  border: 1px solid rgb(218, 215, 215);
  height: 10rem;
  width: 90%;
  border-radius: 5px 5px;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.ads-package:hover {
  border: 1px solid #0b8b3a;
  border: 1px solid var(--logoColor);
  -webkit-transform: scale(1.01);
          transform: scale(1.01);
}
.ads-package h4,
h5 {
  background: #f0fdfa;
  width: 7rem;
  color: #0b8b3a;
  color: var(--logoColor);
  margin: 0.5rem 0.5rem;
  text-align: center;
  border-radius: 5px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.1rem;
}
.opop {
  margin-left: 0.3rem;
}
.dagar-div {
  position: absolute;
  top: -12%;
  left: 0%;
  background: white;
  width: 5rem;
  text-align: center;
  color: #0b8b3a;
  color: var(--logoColor);
  font-weight: bold;
}
.ads-package-inner-icon {
  color: #0b8b3a;
  color: var(--logoColor);
  font-size: 1.2rem;
}
.ads-package-inner p {
}
.adons-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(233, 230, 230, 0.05);
  width: 100%;
  height: 8rem;
  border: 1px solid rgb(216, 215, 215);
  align-items: center;
  border-radius: 5px 5px;
}
.ads-holder-con {
  display: flex;
  justify-content: space-evenly;
}
.ads-btn-container {
  background: #dfe6d8;
  background: var(--navback);
  height: 6rem;
  display: flex;
  justify-content: center;
}
.ads-btn-container button {
  width: 90%;
  height: 3rem;
  padding: 0.5rem;
  font-weight: bold;
  margin: 2rem 0;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(233, 230, 230, 0.05);
  color: white;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 1rem;
  background: #0b8b3a;
}
.ad-form-in {
  font-family: "Roboto Condensed", sans-serif;
  height: 100%;
  margin-bottom: 4rem;
}
.ad-form-in div {
  margin-left: 1rem;
}
.form-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.form-header h1 {
  font-weight: 100;
  border-bottom: 1px solid #dfe6d8;
  border-bottom: 1px solid var(--navback);
}
.cancel-form-btn {
  margin-right: 1rem;
  width: 25px;
  height: 25px;
  border: 1px solid #dfe6d8;
  border: 1px solid var(--navback);
  color: #0b8b3a;
  color: var(--logoColor);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.form-control {
  display: flex;
  flex-direction: column;
  margin: 1rem 1rem;
  width: 80%;
}

label {
  margin-right: 0.5rem;
  margin-left: 0.1rem;
  margin-bottom: 0.1rem;
  font-weight: bold;
  color: #013a17;
  color: var(--heroTextColor);
}
input[type="text"] {
  height: 2rem;
  border-radius: 5px 5px;
  border: 0.5px solid rgb(148, 145, 145);
}
input[type="email"] {
  height: 2rem;
  border-radius: 5px 5px;
  border: 0.5px solid rgb(148, 145, 145);
}
::-webkit-input-placeholder {
  color: #0b8b3a;
  color: var(--logoColor);
}
::placeholder {
  color: #0b8b3a;
  color: var(--logoColor);
}
select {
  height: 2rem;
  background: rgb(231, 229, 229);
  border: 0.5px solid rgb(245, 240, 240);
  border-radius: 5px 5px;
  font-size: 1rem;
}
.form-control p {
  margin-bottom: 0.1rem;
  font-weight: bold;
}
.form-control h2 {
  border-bottom: 0.5px solid #dfe6d8;
  border-bottom: 0.5px solid var(--navback);
  width: 7rem;
  margin-bottom: 0.3rem;
}
input[type="textarea"] {
  border-radius: 5px 5px;
  border: 0.5px solid rgb(231, 225, 225);
}
.uploaded-img {
  height: 100px;
  width: 100px;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.upload-img-inputs {
  margin: 0.3rem 0;
}
.form-ad-btn-cont-sub {
  margin: 2rem 0;
  display: flex;
  flex-direction: column;
}
.form-ad-btn-cont-sub-btn {
  width: 90%;
  height: 2.6rem;
  background: #22c55e;
  background: var(--logoColorLight);
  color: white;
  font-size: 0.9rem;
  letter-spacing: 1px;
}
.form-ad-btn-cont-sub-btn:disabled {
  opacity: 0.5;
}
.lllll {
  display: flex;
}
.lllll p {
  max-width: 80%;
  line-height: 20px;
}
.contact-adress {
  background: #dfe6d8;
  background: var(--navback);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.contact-adress h4 {
  font-size: 1.2rem;
  color: #013a17;
  color: var(--heroTextColor);
}
.contact-adress p {
  margin: 0.2rem 0;
  color: #013a17;
  color: var(--heroTextColor);
}
.up-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid rgb(177, 175, 175);
  width: 200px;
  height: 4rem;
  border-radius: 5px 5px;
}
.new-img-btn {
  background: #22c55e;
  background: var(--logoColorLight);
  color: white;
  font-size: 0.7rem;
  height: 1.2rem;
  width: 5rem;
  opacity: 0.8;
}
.new-img-btn:hover {
  opacity: 1;
  font-weight: bold;
}
.new-img-btn:hover {
  background: white;
  border: 2px solid #22c55e;
  border: 2px solid var(--logoColorLight);
  color: #22c55e;
  color: var(--logoColorLight);
}
.saved-section {
  display: flex;
  overflow-x: scroll;
  justify-content: center;
  width: 100%;
}
.saved-section div {
  margin-right: 0.5rem;
  border: 1px solid #22c55e;
  border: 1px solid var(--logoColorLight);
  width: 200px;
  height: 220px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 5px 5px;
}
.filter-filter-holder {
  background: linear-gradient(
      rgba(0.2, 0.2, 0.2, 0.2),
      rgba(0.2, 0.2, 0.2, 0.2)
    ),
    url(/static/media/filterBackground.2ea010e9.jpg);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.hembanner-banner {
  background: url(/static/media/filterBackground.2ea010e9.jpg);
  height: 400px;
}
.hem {
  background: url(/static/media/filterBackground.2ea010e9.jpg);
}
.selectlist {
  -webkit-transform: translateY(1000%);
          transform: translateY(1000%);
  font-size: 3rem;
  transition: all 0.3 linear;
}
.show-list {
  -webkit-transform: translateY(-5%);
          transform: translateY(-5%);
}
.ooo {
  background: linear-gradient(
      rgba(0.1, 0.1, 0.1, 0.1),
      rgba(0.1, 0.1, 0.1, 0.1)
    ),
    url(/static/media/zanzibarisland.0f6ab1dc.jpg) no-repeat;
  width: 100%;
  height: 320px;
  overflow: hidden;
  object-fit: cover;

  background-position: center;
}
.adbannerp {
  font-family: "Roboto Condensed", sans-serif;
  letter-spacing: 1px;
  font-size: 1.1rem;
}
.adbannerbtn {
  color: white;
  cursor: pointer;
  border: 1px solid white;
  width: 8rem;
  height: 2.4rem;
  letter-spacing: 2px;
  font-weight: bold;
  font-size: 1rem;
  font-family: "Roboto Condensed", sans-serif;
}
#profile {
  font-family: "Roboto Condensed", sans-serif;
  letter-spacing: 1px;
}
.edit-object-file input {
  font-size: 0.7rem;
  margin: 0.1rem 0;
}
@media screen and (max-width: 400px) {
  .edit-object-file {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (min-width: 900px) {
  .adBanner-holder {
    display: none;
  }
  .faq-root {
    max-width: 80%;
  }
  .ajjemen-banner-section {
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .ajjemen-banner-section img {
    width: 400px;
  }
  .ajjemen-banner-section p {
    width: 400px;
  }
  .map-header-title {
    text-align: center;
    font-size: 1.4rem;
  }
}

.faq-root {
  font-family: "Poppins", sans-serif;
}
.map-header-title {
  font-family: "Poppins", sans-serif;
  margin-left: 0.5rem;
  margin-top: 2rem;
}
.poppins {
  font-family: "Poppins", sans-serif;
}
.related-item-container {
  display: flex;
  overflow-x: scroll;
}
.newfilter-p {
  margin-left: 0.5rem;
  margin-top: 0.15rem;
  font-family: "Source Sans Pro", sans-serif;
  color: rgb(121, 120, 120);
  /* max-width: 90%; */
  max-width: 90%;
  line-height: 23px;
}

.titletoprated{
margin-top: 1rem;
padding: 0.5rem;
font-size: 1.7rem;
color: #0b8b3a;
}
.toprateditem{
  width: 100%;
  max-width: 400px;
  background: white;
  height: 100%;
  margin: 0.75rem 1rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 5px;
}

.topratedImage{
  width: 100%;
object-fit: cover;
height: 200px;
border-top-left-radius: 5px;
border-top-right-radius: 5px;
}

.topratedstarsCont{
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
}
.topratedstars{
  color: #00BF93;
  font-size: 1rem;
}
.starIcon{
  color: #00BF93;
  font-size: 1rem;
  margin-right: 0.25rem;
}

.testmRoot{
  display: flex;
  flex-direction: column;
  padding: 1rem;
}
.testmiCont{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 90%;
}
.tesmoLinks{
  color: #1d3a3c;
  text-decoration: none;
  font-size: 0.85rem;
  margin: 0.5rem 0.2rem;
}
